import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {AppEvents, DateTime, PanelProps, dateTime } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { Button, CustomScrollbar, DateTimePicker, Field, Input, RadioButtonGroup, Tag, useStyles2, useTheme2 } from '@grafana/ui';

//import { AppEvents } from '@grafana/data';
import { getAppEvents } from '@grafana/runtime';
import mqtt from 'mqtt'
import { createTheme, Paper, TableBody, TableCell, TableContainer, Table,TableHead, TableRow, ThemeProvider } from '@mui/material';
import { random } from 'lodash';

interface Props extends PanelProps<SimpleOptions> {}
interface Device{
  name: string|null;
  address: number;
  highlighted: boolean;
  coef: number;
  turnOffLength: number;
  error: {
    msg: string;
    show: boolean;
  }
}

interface DeviceInfo {
  address: number;
  name: string|null;
}

interface PanelEvent extends Event {
  detail: {
    key: string;
    dev: Device; // Adjust this type according to your Device type
  };
}

interface TurnoffSettings {
  id: Number,
  groupName: string,
  settings: JSON
}


const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    ` ,
    deleteButton: css`
    display: inline-block;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  `,
 tableCell: css`
     display: table-cell;
     padding: 16px; 
     font-size: 0.875rem;
     text-align: left; 
     font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
     font-weight: 400; 
     line-height: 1.43; 
     letter-spacing: 0.01071em; 
     vertical-align: inherit; 
    `
  };
};



export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const appEvents = getAppEvents();

  const [client,setClient] = useState<mqtt.MqttClient>();
  const [Devices,setDevices] = useState<Device[]>([]);
  //const [__Devices,__setDevices] = useState<Device[]>([]);
  const [_turnOffs,setTurnOffs] = useState<TurnoffSettings[]>([]);
  //const [coefValue, setCoefValue] = useState('');
  

  const createQuery = (
    from: string,
    to: string,
    datasourceUID: string | null = null,
    rawSql: string
  ) => {
    return {
      from: from,
      to: to,
      queries: [
        {
          refId: 'A',
          datasource:{
            uid: datasourceUID
          },
          //datasourceId: datasourceId,
          rawSql: rawSql,
          format: 'table',
        },
      ],
    };
  };
  const  fetchData = (
    apiUrl: string,
    method: string,
    headers: Record<string, string>,
    body?: any
  ): Promise<any> => {
    return fetch(apiUrl, {
      method: method,
      headers: headers,
      credentials: 'include',
      body: body ? JSON.stringify(body) : undefined,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
        return null;
      });
  };
  //1. fetch data
  const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
  const method = 'POST';
  const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

  //table select for vemat_devices 
  const sqlCommands = [
  `SELECT * FROM turnoff where done = false order by id asc`

];
  //const rawSql = `SELECT address,name,coef FROM vemat_devices order by id desc`;

  const select_turnoffs = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);

  //const headers = {'Content-Type': 'application/json'};
  const headers = useMemo(() => {
    // Replace the following with your actual header initialization logic
    return {
      //Authorization: 'Bearer your_access_token',
      'Content-Type': 'application/json',
    };
  }, []);

  const reloadTable = useCallback( () => {

    fetchData(grafanaApiBaseUrl, method, headers, select_turnoffs).then(data => {
      if (data) {
        if(data.results.A.frames.length>0){
        const ids = data.results.A.frames[0].data.values[0];
        const names = data.results.A.frames[0].data.values[1];
        const settings = data.results.A.frames[0].data.values[2];
      
        const mappedData = ids.map((_id: number, index: number) => ({
          id:_id,
          groupName: names[index],
          settings: settings[index],
        }));
        //map error array
        setTurnOffs(mappedData);
      }
        //napamovani nactenych dat do StateVariable
      }
    });
  
   
  }, [grafanaApiBaseUrl, method, headers, select_turnoffs, setTurnOffs ]);

  useEffect(() => {
    reloadTable();
    return () => {
      // Cleanup code here
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function generateRandomClientId(prefix: string, length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomPart = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    return `${prefix}${randomPart}`;
  }



useEffect(()=>{
  //console.log("F");
  const mqttConnect = (host: string) => {
    const clientIdPrefix = 'client-';
    const clientIdLength = 10; // Adjust the length as needed
    const randomClientId = generateRandomClientId(clientIdPrefix, clientIdLength);
      //const brokerUrl = 'wss://46.36.40.68:6969';
      //setClient(connect(host));
    setClient(
      mqtt.connect(host, {
    clientId: randomClientId,
    username: 'vemat',
    password: 'vemat1337',
    rejectUnauthorized: false,
    port: 6969
   
    }));
  };

    mqttConnect('mqtts://vemat.edima.io:6969');
    //mqttConnect('mqtts://46.36.40.68:6969');

},[setClient]);



useEffect(() => {
  if (client) {
    //console.log(client)
    client.on('connect', () => {
      //setConnectStatus('Connected');
      console.log("Connected");
    });
    client.on('error', (err: any) => {
      console.error('Connection error: ', err);
      client.end();
    });
    client.on('reconnect', () => {
      //setConnectStatus('Reconnecting');
    });
    client.on('message', (topic: any, message: any) => {
      //const payload = { topic, message: message.toString() };
      //setPayload(payload);
    });
  }
}, [client]);

  
  const [selectedOption,setSelectedOption] = useState('instantoff');
  const [date, setDate] = useState<DateTime>(dateTime(Date.now()));
 const [groupName,setGroupName] = useState("");
  useEffect(() => {
    // Function to handle the event
    const handleEvent = (event: Event) => {
      // Your event handling logic here
      const panelEvent = event as PanelEvent;
      const data = panelEvent.detail;
      const state = data.key;

  
      if(state==="addDevice"){
        //let _devices = [...devices];
        let _devices = [...Devices];
        data.dev.error  ={
          msg: "",
          show: false
        };
        
        _devices.push(data.dev);
        setDevices(_devices);
        
      };
      if(state === "removeDevice"){
          let _devices = [...Devices];
         const indexToRemove = _devices.findIndex(obj => obj.address === data.dev.address);
          if (indexToRemove !== -1) {
    _devices.splice(indexToRemove, 1);
    setDevices(_devices);
  } 
  }
    };

    // Add the event listener when the component mounts
    document.addEventListener('panel1ToPanel2Event', handleEvent);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('panel1ToPanel2Event', handleEvent);
    };
  }, [Devices]); // Empty dependency array ensures this effect runs only once

  const btn_commit = async() =>{


  if(Devices.length===0){
    appEvents.publish({
      type: AppEvents.alertError.name,
      payload: ["Nebyly zvoleny zařízení"],
    });
    return;
    }

  switch(selectedOption){
    case "instantoff":
    
      //console.log("bp");
      sendMqttToTurnOff(Devices);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ["Poslan prikaz k vypnuti"],
      });

    //sendMQTTOFF
      break;
      case "timedoff":

      let toSend: DeviceInfo[] =[];
      Devices.forEach(dev => {
      toSend.push({"address":dev.address,"name":dev.name});
    });

      let json_settings = {
        "command":"timedoff",
        "deviceList":toSend,
        "when":date.unix(),
        "groupName": groupName
        //#settings -  {deviceList:[1,2,3],"command":""}
      }
      //what to push in db.
      let sqlInsert = `INSERT INTO TURNOFF(groupname, settings) VALUES ('${groupName}', '${JSON.stringify(json_settings)}')`;
     //console.log(sqlInsert);
     
     sendMqtt({"command":"reloadTurnOffTable","random":random(1000)});

      //sqlInsert = `INSERT INTO report_settings(group_name, settings) VALUES ('ss', '{"groupName":"ss","deviceList":"30","email":"jirkadep@seznam.cz","reportRate":"weekly","shift":"ranni"}')`;
    const updateQuery = createQuery('now-1h', 'now', datasourceUID, sqlInsert);
      await fetchData(grafanaApiBaseUrl, method, headers, updateQuery);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ["Přidáno časované vypnutí"],
      });
      document.dispatchEvent(new  CustomEvent('updateTurnOffList', { detail: { key: 'update'} }));
        break;
        case "quantityoff":
    
         
          async function fetchAndUpdateData() {
            const _dev = Devices;
            console.log("wtf");
            for(let i=0;i<_dev.length;i++){
              if(isNaN(Number( _dev[i].coef)) || _dev[i].coef <= 0 ){
                //_dev[i].error.msg = "Prázdný koeficient, nastavit v zařízení.";
                appEvents.publish({
                  type: AppEvents.alertError.name,
                  payload: ["Prázdný koeficient, nastavit v zařízení."],
                });

                return false;
              }

              if(isNaN(Number( _dev[i].turnOffLength)) || _dev[i].turnOffLength <= 0 ){
                appEvents.publish({
                  type: AppEvents.alertError.name,
                  payload: ["Chyba v délce, musí být > 0 a kladné číslo"],
                });

                return false;
              }
            }
            const promises = Devices.map(dev => {
              
              const settings = {
                command: 'quantityoff',
                device: {
                  address: dev.address,
                  name: dev.name,
                  coef: dev.coef,
                  turnoffLength: dev.turnOffLength,
                 
                },
                when: date.unix(),
              };
              const _sqlInsert = `INSERT INTO TURNOFF(groupname, settings) VALUES ('${groupName}', '${JSON.stringify(settings)}')`;
              const _updateQuery = createQuery('now-1h', 'now', datasourceUID, _sqlInsert);
          
              // Use the fetchData function to fetch data from devices
              return fetchData(grafanaApiBaseUrl, method, headers, _updateQuery);
            });
          
            try {
              await Promise.all(promises);
              //console.log("Data fetched");
          
              document.dispatchEvent(new CustomEvent('updateAmountOffList', { detail: { key: 'update' } }));


              appEvents.publish({
                type: AppEvents.alertSuccess.name,
                payload: ["Přidáno."],
              });



              //console.log("Dispatching event");
            } catch (error) {
              console.error("Error fetching data:", error);
            }
            return true;
          }
          
          if(await fetchAndUpdateData()){
          let _str = {"command":"reloadTurnOffTable","random":random(1000)};
          sendMqtt(_str);
          }else{
            return;
          }
          //console.log("mqtt_send",_str);
          break;
          case "solaroff":
          
  let _toSend: DeviceInfo[] =[];
      Devices.forEach(dev => {
      _toSend.push({"address":dev.address,"name":dev.name});
    });

      let _json_settings = {
        "command":"solaroff",
        "deviceList":_toSend,
        "when":date.unix(),
        "groupName": groupName
        //#settings -  {deviceList:[1,2,3],"command":""}
      }
      //what to push in db.
      let _sqlInsert = `INSERT INTO TURNOFF(groupname, settings) VALUES ('${groupName}', '${JSON.stringify(_json_settings)}')`;
  

      //sqlInsert = `INSERT INTO report_settings(group_name, settings) VALUES ('ss', '{"groupName":"ss","deviceList":"30","email":"jirkadep@seznam.cz","reportRate":"weekly","shift":"ranni"}')`;
    const _updateQuery = createQuery('now-1h', 'now', datasourceUID, _sqlInsert);
      await fetchData(grafanaApiBaseUrl, method, headers, _updateQuery);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ["Přidáno."],
      });
      document.dispatchEvent(new  CustomEvent('updateTurnOffList', { detail: { key: 'update'} }));
       // send mqtt command to reload database table in python
  //console.log("Publishing..");
  let payload = {"command":"reloadTurnOffTable","random":random(1000)};

  sendMqtt(payload);
         break;
        
  }

  document.dispatchEvent(new  CustomEvent('canvas_clear', { detail: { key: 'canvas_clear'} }));
  setDevices([]);

}

function sendMqttToTurnOff(devs: Device[]){

  let toSend: Number[]=[];
  devs.forEach(dev => {
  toSend.push(dev.address);
});

if(client!==undefined&&toSend.length>0){

  let jsonSend = {"command":"instantoff","deviceList":toSend};
  client.subscribe("VEMAT", (err) => {
    if (!err) {
      client.publish("VEMAT", JSON.stringify(jsonSend));
    }
  });
}
}

function sendMqtt(payload: any){

  //console.log("Send it");
if(client!==undefined){

  let jsonSend = payload;// {"command":"instantoff"};
  client.subscribe("VEMAT", (err) => {
    if (!err) {
      client.publish("VEMAT", JSON.stringify(jsonSend));
    }
  });
}
}

function clickOnTag(deviceInfo: DeviceInfo) {
  console.log("deviceInfo: ", deviceInfo);
  let newDev = Devices.filter(device => device.address !== deviceInfo.address && device.name !== deviceInfo.name);
  setDevices(newDev);
  document.dispatchEvent(new CustomEvent('delete_specific_element', { detail: { address: deviceInfo.address, name: deviceInfo.name } }));
}

const radio_options = [
  { label: 'Vypnout hned', value: 'instantoff' },
  { label: 'Časové vypnutí', value: 'timedoff' },
  { label: 'Množstevní vypnutí', value: 'quantityoff' },
  { label: 'Solární panel', value: 'solaroff' }
];

 function setValue(value: string,id: number){

let _devices = [...Devices];
_devices[id].turnOffLength=parseFloat(value);
setDevices(old => _devices);
}
const theme = useTheme2();
const styles = useStyles2(getStyles);
const tag_inline = {
  display: 'inline-block',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  color: theme.colors.primary.contrastText,
  padding: '2px',
  margin: '2px'
};
const btn_margin = {
  margin: '5px'
}

 
const table_theme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.primary.contrastText
         
        },
        // Customize the disabled input text color
        
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        head: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
          padding: '5px 16px', // Adjust the padding as needed
        },
        root: {
          backgroundColor: theme.colors.background.primary,
          border: `1px solid ${theme.colors.background.primary}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: theme.typography.fontSize,
          color: theme.colors.text.maxContrast,
        },
      },
    },
  },
});
  return (

    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
          maxHeight: ${height};
          overflowY: auto;
          overflowX: auto;
        `
      )}
    >



      
        {Devices.map((row, index) => (
          /*
          <div key={index}>          
              {row.name}
          </div>
*/
<Tag
          key={row.address}
          name={row.name === null ? "" : row.name + " @" + row.address}
          style={tag_inline}
          colorIndex={13}
          onClick={() => clickOnTag({ address: row.address, name: row.name })}
          icon="trash-alt"
        />


        ))}
        <br></br>
        <RadioButtonGroup
          options={radio_options}
          value={selectedOption}
          fullWidth={true}
          onChange={v => setSelectedOption(v!)} 
          className={cx(
            styles.wrapper,
            css`
              width: ${width}px;
              maxWidth: ${width};
              overflowX: auto;
            `
          )}
/>
<br></br>
<div>


<Button 
size={"md"}
type="submit"
onClick={()=>btn_commit()}
style={btn_margin}
>
Provést

<br></br>
</Button>
</div>
{selectedOption === 'timedoff' &&
        <><Input
          placeholder='Název skupiny'
          className={cx(
            styles.wrapper,
            css`
              width: ${width}px;
              maxWidth: ${width};
              overflowX: auto;
            `
          )}
          value={groupName} onChange={e => setGroupName(e.currentTarget.value)} /><DateTimePicker label={"Zvolit datum"} date={date}
            onChange={newValue => {
              //action('on change')(newValue);
              setDate(newValue);

            } } /></>


}
{selectedOption === 'solaroff' && 
<Input
          placeholder='Název skupiny'
          width={62}
          value={groupName} onChange={e => setGroupName(e.currentTarget.value)}
          className={cx(
            styles.wrapper,
            css`
              width: ${width}px;
              maxWidth: ${width};
              overflowX: auto;
            `
          )}
          />
          
}
{selectedOption === 'quantityoff' &&
 <>
        <ThemeProvider theme={table_theme}>
            <CustomScrollbar>

              <TableContainer component={Paper} style={{ maxHeight: 900, backgroundColor: theme.colors.background.primary }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Název zařízení</TableCell>
                      <TableCell>Koeficient</TableCell>
                      <TableCell>Množství</TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {Devices?.map((row, index) => {

                      return (
                        <TableRow key={index}
                        className={cx(
                          styles.wrapper,
                          css`
                            width: ${width}px;
                            maxWidth: ${width};
                            overflowX: auto;
                            maxHeight: ${height-200};
                            overflowY: auto;
                          `
                        )}
                        >
                          <TableCell>
                            <Input
                              disabled={true}
                              value={row.name===null?"":row.name} />
                          </TableCell>

                          <TableCell>
                         
                            <Input
                              disabled={true}
                              value={row.coef} />
                             
                          </TableCell>
                   
                        <TableCell>
                        <div>
                        <Field invalid={row.error.show}
                         error={row.error.msg}>
                        <Input 
                         type="number"
                         step="any"
                        value={row.turnOffLength} onChange={(e) => setValue(e.currentTarget.value,index)} />
                     
        </Field>

        </div>
                        </TableCell>
                       

                

                        </TableRow>
                      );
                    })}



                  </TableBody>
                </Table>

                <div>
                  <div>
                  </div>


                </div>
              </TableContainer>
            </CustomScrollbar>
          </ThemeProvider></>
}

    </div>
  );
};
